import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  user_chat_get_chatid,
  user_chat_raise,
  user_ticket_get,
  user_ticket_raise,
} from "../../../common/Api";
import { toast } from "react-toastify";

export default function SupportComponent() {
  const [show, setShow] = useState(false);
  const [chat, setChat] = useState(false);
  const [chatprocess, setChatProcess] = useState({});
  const [allchat, setAllChat] = useState([]);
  const handleClose = () => {
    setShow(false);
    setChat(false);
  };
  const [message, setMessage] = useState("");
  const [reply, setReply] = useState("");
  const [ticket, setTicket] = useState([]);
  const { user, token } = useSelector((state) => state.user);

  const handleTicketRaise = async () => {
    setShow(true);
  };

  const submitTicketRaise = async () => {
    try {
      const datas = {
        message: message,
      };
      const data = await user_ticket_raise(user._id, token, datas);

      if (data.status == true) {
        toast.success(data.message);
        getUserTicketRaise();
        setMessage("");
        setShow(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getUserTicketRaise = async () => {
    try {
      const data = await user_ticket_get(user._id, token);
      console.log(data);
      setTicket(data.data);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getUserTicketRaise();
  }, [user._id, token]);

  const handleAllChat = async (items) => {
    console.log(items);
    const data = await user_chat_get_chatid(items.supportId, token);
    console.log("data", data);
    setAllChat(data.data);
    setChat(true);
    setChatProcess(items);
  };

  const handleLiveChatProcess = async (id) => {
    try {
      const datas = {
        supportId: id,
        frommessage: reply,
      };
      const data = await user_chat_raise(user._id, token, datas);
      console.log(data);
      setAllChat(data.data);
      if (data.status == true) {
        const datas = await user_chat_get_chatid(id, token);
        setAllChat(datas.data);
        setReply("");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div class="help_desk_sec">
      <h2>Help Desk</h2>
      <div class="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-6">
              <div class="trasction_tab_menus_searchbar searchbar">
                <input
                  type="text"
                  placeholder="Search..."
                  class="search-input"
                />
                <i class="fa-solid fa-magnifying-glass search-icon"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6"></div>
      </div>

      <div class="sort_list d-block d-md-flex mt-5">
        <div class="d-flex ms-0 ms-md-3">
          <div class="dropdown_status">
            <p
              class="create_ticket ms-auto d-flex gap-2 align-items-center"
              style={{ cursor: "pointer" }}
              onClick={handleTicketRaise}
            >
              <i class="fa-solid fa-plus create_ticket_icon"></i>
              <span>Create Ticket</span>
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 table-responsive">
          <div class="trasaction_table mt-5 w-100" id="deposit">
            <table className="w-100">
              <tbody>
                <tr className="text-nowrap">
                  <th>Sr.No.</th>
                  <th>Support ID</th>
                  <th>Date & Time</th>
                  <th>Subject</th>
                  <th>Status</th>
                  <th></th>
                </tr>
                {ticket.map((items, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{items?.supportId}</td>
                      <td>
                        {moment(items?.creditDate).format(
                          "DD MMMM YYYY, HH:mm"
                        )}
                      </td>
                      <td>{items?.message}</td>
                      <td>
                        <i class="fa-solid fa-circle status-icon"></i>{" "}
                        {items?.status}
                      </td>
                      <td>
                        <i
                          class="fa-solid fa-eye"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleAllChat(items)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ticket Raise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div class="mb-6">
              <label class="form-label" for="basic-icon-default-message">
                Message
              </label>
              <div class="input-group input-group-merge mt-2">
                <span id="basic-icon-default-message2" class="input-group-text">
                  <i class="ti ti-message-dots"></i>
                </span>
                <textarea
                  id="basic-icon-default-message"
                  class="form-control messageBox"
                  placeholder="Write a message"
                  aria-label="Hi, Do you have a moment to talk Joe?"
                  aria-describedby="basic-icon-default-message2"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={submitTicketRaise}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={chat} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-white">
            Chat Support Related to {chatprocess?.message}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="container px-0">
            <div class="chat-box">
              {allchat?.map((items, i) => {
                return (
                  <div key={i}>
                    {items?.usermessage && (
                      <div class="message primary">
                        {items?.usermessage}{" "}
                        <div class="timestamp">
                          {moment(items?.creditDate).fromNow()}
                        </div>
                      </div>
                    )}
                    {items?.adminmessage && (
                      <>
                        <div className="bot-info" style={{ textAlign: "left" }}>
                          <small className="bot-text">Sent by BT BOT</small>
                        </div>{" "}
                        <div class="message secondary">
                          {items?.adminmessage}{" "}
                          <div class="timestamp">
                            {moment(items?.creditDate).fromNow()}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
            <div class="input-area pt-4">
              <input
                type="text"
                value={reply}
                onChange={(e) => setReply(e.target.value)}
                placeholder="Write a message"
              />
              <button
                onClick={() => handleLiveChatProcess(chatprocess?.supportId)}
              >
                <i class="fas fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
