import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
  allUserTicket,
  statusChangeSupport,
  user_chat_get_chatid,
  user_chat_raise,
  user_ticket_get,
  user_ticket_raise,
  userChatAdminHandle,
} from "../../common/Api";
import DataTable from "react-data-table-component";
import { Colors } from "chart.js";

export default function Support() {
  const [show, setShow] = useState(false);
  const [chat, setChat] = useState(false);
  const [chatprocess, setChatProcess] = useState({});
  const [allchat, setAllChat] = useState([]);
  const [userinfo, setuserInfo] = useState({});

  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Set items per page

  const handleClose = () => {
    setShow(false);
    setChat(false);
  };
  const [message, setMessage] = useState("");
  const [reply, setReply] = useState("");
  const [ticket, setTicket] = useState([]);
  const { user, token } = useSelector((state) => state.user);

  const handleTicketRaise = async () => {
    setShow(true);
  };

  const submitTicketRaise = async () => {
    try {
      const datas = {
        message: message,
      };
      const data = await user_ticket_raise(user._id, token, datas);

      if (data.status == true) {
        toast.success(data.message);
        getUserTicketRaise();
        setMessage("");
        setShow(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getUserTicketRaise = async () => {
    try {
      const data = await allUserTicket(token);
      setTicket(data.data);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    setInterval(() => {
      getUserTicketRaise();
    }, 2000);
    //getUserTicketRaise()
  }, []);

  const handleAllChat = async (items) => {
    //console.log(items)
    setuserInfo(items.userId);
    const data = await user_chat_get_chatid(items.supportId, token);
    //console.log('data', data)
    setAllChat(data.data);
    setChat(true);
    setChatProcess(items);
  };

  const handleLiveChatProcess = async (id) => {
    try {
      const datas = {
        supportId: id,
        frommessage: reply,
      };
      const data = await userChatAdminHandle(token, datas);
      if (data.status == true) {
        const datas = await user_chat_get_chatid(id, token);
        setAllChat(datas.data);
        setReply("");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const hanldeStatusChangeSupport = async (id) => {
    try {
      const datas = {
        supportId: id,
      };
      const data = await statusChangeSupport(token, datas);
      if (data.status == true) {
        getUserTicketRaise();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
      width: "50px",
    },
    { name: "Support ID", selector: (row) => row?.supportId || "-NA-" },
    {
      name: "Date & Time",
      selector: (row) => moment(row?.creditDate).format("DD MMMM YYYY, HH:mm"),
    },
    // { name: 'Plan', selector: row => row.planId?.name },

    { name: "Subject", selector: (row) => row?.message },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row?.status == "active" ? (
            <>
              <i class="fas fa-dot-circle" style={{ color: "green" }}></i>{" "}
              Active
            </>
          ) : (
            <>
              <i class="fas fa-dot-circle" style={{ color: "red" }}></i> Closed
            </>
          )}
        </>
      ),
    },
    {
      name: "Change Status",
      selector: (row) => (
        <>
          {row?.status == "active" && (
            <div className="d-flex">
              <select id="status" className="form-control">
                <option value="select">Change Status</option>
                <option value="close">Close</option>
              </select>
              <button onClick={() => hanldeStatusChangeSupport(row?.supportId)}>
                Submit
              </button>
            </div>
          )}
        </>
      ),
    },
    {
      name: "View",
      selector: (row) => (
        <>
          <i
            class="fa-solid fa-eye"
            style={{ cursor: "pointer" }}
            onClick={() => handleAllChat(row)}
          ></i>
        </>
      ),
    },
  ];

  return (
    <div class="help_desk_secs" style={{ margin: "10px" }}>
      <h2>Help Desk</h2>
      <div class="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-6">
              <div class="trasction_tab_menus_searchbar searchbar">
                <input
                  type="text"
                  placeholder="Search..."
                  class="search-input"
                />
                <i class="fa-solid fa-magnifying-glass search-icon"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6"></div>
      </div>

      {/* <div class="sort_list d-block d-md-flex mt-5">
                <div class="d-flex ms-0 ms-md-3">
                    <div class="dropdown_status">
                        <p class="create_ticket ms-auto d-flex gap-2 align-items-center" style={{ cursor: "pointer" }} onClick={handleTicketRaise}>
                            <i class="fa-solid fa-plus create_ticket_icon"></i>
                            <span>Create Ticket</span>
                        </p>
                    </div>
                </div>
            </div> */}
      <div className="row">
        <div className="col-lg-12 table-responsive">
          <div class="trasaction_table mt-5 w-100" id="deposit">
            {/* <table className='w-100'>
                            <tbody>
                                <tr className='text-nowrap'>
                                    <th>Sr.No.</th>
                                    <th>Support ID</th>
                                    <th>Date & Time</th>
                                    <th>Subject</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                                {
                                    ticket.map((items, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{items?.supportId}</td>
                                                <td>{moment(items?.creditDate).format('DD MMMM YYYY, HH:mm')}</td>
                                                <td>{items?.message}</td>
                                                <td><i class="fa-solid fa-circle status-icon"></i> {items?.status}</td>
                                                <td><i class="fa-solid fa-eye" style={{ cursor: "pointer" }} onClick={() => handleAllChat(items)}></i></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table> */}
            <DataTable
              columns={columns}
              data={ticket}
              pagination
              highlightOnHover
              responsive
              paginationDefaultPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
              noDataComponent={
                <p style={{ margin: "10px", textAlign: "center" }}>
                  There are no records to display
                </p>
              }
            />
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ticket Raise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div class="mb-6">
              <label class="form-label" for="basic-icon-default-message">
                Message
              </label>
              <div class="input-group input-group-merge mt-2">
                <span id="basic-icon-default-message2" class="input-group-text">
                  <i class="ti ti-message-dots"></i>
                </span>
                <textarea
                  id="basic-icon-default-message"
                  class="form-control messageBox"
                  placeholder="Write a message"
                  aria-label="Hi, Do you have a moment to talk Joe?"
                  aria-describedby="basic-icon-default-message2"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={submitTicketRaise}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={chat} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-white">
            Chat Support Related to {chatprocess?.message}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="containers px-0">
            <div class="chat-box">
              {allchat?.map((items, i) => {
                return (
                  <div key={i}>
                    {
                      items?.usermessage && (
                        <>
                          <small style={{ color: "green" }}>
                            {userinfo.email} ({userinfo.btucode})
                          </small>
                          <div class="message secondary">
                            {items?.usermessage}{" "}
                            <div class="timestamp">
                              {moment(items?.creditDate).fromNow()}
                            </div>
                          </div>
                        </>
                      )
                      // <div class="message secondary">{items?.usermessage} <div class="timestamp">{moment(items?.creditDate).fromNow()}</div></div>
                    }
                    {items?.adminmessage && (
                      <div class="message primary ">
                        {items?.adminmessage}{" "}
                        <div class="timestamp">
                          {moment(items?.creditDate).fromNow()}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div class="input-area pt-4">
              {chatprocess.status == "active" && (
                <>
                  <input
                    type="text"
                    value={reply}
                    onChange={(e) => setReply(e.target.value)}
                    placeholder="Write a message"
                  />
                  <button
                    onClick={() =>
                      handleLiveChatProcess(chatprocess?.supportId)
                    }
                  >
                    <i class="fas fa-paper-plane"></i>
                  </button>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
